<template>
  <div class="analysis_warp">
    <TopCard ref="TopCardRef"
             text="答案解析">
      <template #right>
        <div class="right_img">
          <img src="@/static/top_opertion/up.png"
               @click="previous"
               alt="">
          <img src="@/static/top_opertion/down.png"
               @click="next"
               alt="">
          <img src="@/static/top_opertion/card.png"
               @click="card"
               alt="">
        </div>
      </template>
    </TopCard>

    <div class="content_wrap">
      <div class="leftTopicHead">
        <!-- {{info.volist[paperIndex]}} -->
        <LeftTopic :topic="info.volist[paperIndex]" />
      </div>
      <div class="right_info">
        <RightInfo :topic="info.volist[paperIndex]" />
      </div>
    </div>
    <!-- <analysisOpertion ref="analysisOpertionRef"
                      v-if="info.volist[paperIndex]"
                      :show="info.volist[paperIndex].has_favorite"
                      :info="info.volist[paperIndex]" /> -->
    <!-- <analysisInfo ref="analysisInfoRef"
                  :info="info.volist[paperIndex]" /> -->
    <!-- <Collect ref="CollectRef" /> -->
  </div>
</template>

<script setup>
import TopCard from '@/views/components/top_card/index.vue'
import { reactive, ref } from '@vue/reactivity';
import { onActivated, onMounted } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router'
import { getAnalysi } from '@/api/paper.js'
import LeftTopic from './components/leftTopic.vue'
import RightInfo from './components/rightInfo.vue'
import { message } from 'ant-design-vue';


const router = useRouter();
const route = useRoute()

let info = reactive({ volist: [] })
let paperIndex = ref(0)


onActivated(() => {
  if (window.sessionStorage.getItem('analysisCardIndex')) {
    paperIndex.value = Number(window.sessionStorage.getItem('analysisCardIndex'))
  }
})

onMounted(() => {
  if (route.query.user_paper_id) getPaperInfo(route.query.user_paper_id)
  if (window.sessionStorage.getItem('analysisCardIndex')) paperIndex.value = Number(window.sessionStorage.getItem('analysisCardIndex'))
})

// 获取试卷详情
const getPaperInfo = async (user_paper_id) => {
  const { data } = await getAnalysi({ user_paper_id })
  for (const key in data) {
    info[key] = data[key]
  }
}

// 点击答题卡
const card = () => {
  window.sessionStorage.setItem('analysisCardIndex', paperIndex.value)
  window.sessionStorage.setItem('myTestPaperAnalsis', JSON.stringify(info))
  router.push('/analysis/card')
}

// 上一题
const previous = () => {
  if (paperIndex.value == 0) {
    return
  }
  // if (document.getElementsByClassName('right_topic_info')[0]) {
  //   console.log('top')
  //   document.getElementsByClassName('right_topic_info')[0].scrollTop = 0
  // }
  paperIndex.value -= 1
}

const next = () => {
  if (info.volist.length - 1 == paperIndex.value) {
    card()
    return
  }
  // if (document.getElementsByClassName('right_topic_info')[0]) {
  //   console.log('top')
  //         document.getElementsByClassName('right_topic_info')[0].scrollTop = 0
  // }
  paperIndex.value += 1
}

const collect = () => {
  if (info.volist[paperIndex.value].has_favorite) {
    message.warning('已经收藏过啦')
    return
  }
  let form = {
    user_paper_detail_id: info.volist[paperIndex.value].user_paper_detail_id,
    tags: ''
  }
  // this.$refs.CollectRef.form = form
  // this.$refs.CollectRef.dialogVisible = true
}

</script>

<style lang="scss" scoped>
.analysis_warp {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  .right_img {
    img {
      width: 36rem;
      height: 36rem;
      margin-right: 30rem;
    }
  }
  .content_wrap {
    display: flex;
    height: calc(100vh - 100rem);
    .leftTopicHead {
      width: 830rem;
      padding: 30rem;
      background: #ffffff;
      box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
      margin-right: 60rem;
      margin-left: 50rem;
      box-shadow: inset 0px -1px 0px 0px rgba(229, 229, 229, 1);
    }
    .right_info {
      flex: 1;
      background: #ffffff;
      box-shadow: inset 0rem -1rem 0rem 0rem rgba(229, 229, 229, 1);
    }
  }
}
</style>