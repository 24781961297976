<template>
  <div class="topic_info">
    <div class="title">
      <span>
        <!-- 题目ID：{{rands(topic.question_id)}} -->
        题目ID：{{topic.question_id}}

      </span>
      <span>
        题目分数：{{topic.question_score}}
      </span>
      <span>
        我的得分：{{topic.user_score}}
      </span>
      <span>
        <img src="@/static/top_opertion/biji.png"
             @click="toPost"
             v-if="discuss"
             alt="发帖"
             title='发帖'>
        <img src="@/static/top_opertion/press.png"
             @click="press"
             alt=""
             title='反馈'>
        <img src="@/static/top_opertion/collect.png"
             @click="collect"
             alt=""
             title='收藏'>
        <!-- <img src="@/assets/top_opertion/ysc.png"
           v-if="$route.path == '/analysis' && show"
           @click="collect"
           alt=""> -->
      </span>
    </div>
    <div class="list">

      <div class="item">
        <a-collapse v-model:activeKey="activeKey"
                    expandIconPosition="right"
                    accordion>
          <a-collapse-panel key="1"
                            header="题目解析">
            <div class="content"
                 v-html="topic.question_explain"></div>
          </a-collapse-panel>
          <a-collapse-panel key="2"
                            header="题目答案">
            <div class="content"
                 v-html="topic.question_answer"></div>
          </a-collapse-panel>
          <a-collapse-panel key="3"
                            header="我的答案">
            <div class="content"
                 v-html="topic.user_answer"></div>
          </a-collapse-panel>
          <a-collapse-panel key="4"
                            header="我的评语">
            <div class="content"
                 v-html="topic.comment_to_user"></div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
    <PressDialog ref="pressDialogRef" />
    <Collect ref="collectRef" />
    <PostForm ref="FormRef"
              title='发帖'
              type='1'
              @submit="submit"
              @cancel='cancel'
              :isDisable='true' />
  </div>
</template>

<script setup>
import { rands } from '@/utils/rand.js'
import { ref, toRefs } from '@vue/reactivity'
import { nextTick, onMounted } from 'vue'
import PressDialog from './pressDialog.vue'
import Collect from './collect.vue'
import PostForm from '@/views/dayi/components/form.vue'
import { message } from 'ant-design-vue'
import { addPost } from '@/api/post.js'
const pData = defineProps({
  topic: {
    type: Object,
    default: () => {
      return {
        question_id: '',
        question_score: '',
        user_score: '',
      }
    }
  }
})
let discuss = ref(1)
onMounted(() => {
  discuss.value = JSON.parse(window.localStorage.getItem('userInfo')).discuss
})
let { topic } = toRefs(pData)
let pressDialogRef = ref(null)
let collectRef = ref(null)
let FormRef = ref(null)
let activeKey = ref(['1', '2'])

// 点击反馈按钮
const press = () => {
  let form = {
    user_paper_detail_id: topic.value.user_paper_detail_id,
    category: 0,
    comment: '',
    question_id: topic.value.question_id
  }
  pressDialogRef.value.dialogVisible = true
  pressDialogRef.value.setData(form)
}

const collect = () => {
  if (topic.has_favorite) {
    message.warning('已经收藏过啦')
    return
  }
  let form = {
    user_paper_detail_id: topic.value.user_paper_detail_id,
    tags: ''
  }
  collectRef.value.dialogVisible = true
  collectRef.value.setData(form)
}
//点击发帖按钮
const toPost = () => {
  console.log('发帖')
  let form = {
    discuss_title: '',
    discuss_content: '',
    board_id: topic.value.subject_id,
    // question_id: rands(topic.value.question_id),
    question_id: topic.value.question_id,
    discuss_image: []
  }
  nextTick(() => {
    FormRef.value.form = form
    FormRef.value.dialogVisible = true
  })
}
//发帖弹窗》提交
const submit = async () => {
  const { status } = await addPost(
    FormRef.value.form1
  )
  if (status == 200) {
    message.success('发帖成功')
    cancel()
  }

}
const cancel = () => {
  FormRef.value.dialogVisible = false
}

</script>

<style lang="scss" scoped>
.topic_info {
  .title {
    padding: 16rem 30rem;
    background: #eff8ff;
    font-size: 20rem;
    font-weight: bold;
    span {
      &:nth-child(1) {
        color: #333333;
        font-weight: 500;
      }
      &:nth-child(2) {
        margin-left: 173rem;
      }
      &:nth-child(3) {
        margin-left: 30rem;
      }
      img {
        width: 36rem;
        height: 36rem;
        margin-left: 30rem;
        cursor: pointer;
      }
    }
  }
  .list {
    margin: 30rem;
    margin-top: 0;
    font-size: 20rem;
    font-weight: 500;
    height: calc(100vh - 198rem);
    overflow-y: auto;
    .item {
      color: #333333;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60rem;
        background: #f6f6f6;
        border-radius: 10rem;
        div {
          &:nth-child(1) {
            text-indent: 20rem;
          }
          &:nth-child(2) {
            margin-right: 30rem;
          }
        }
      }
    }
    .content {
      font-size: 20rem;
      margin: 20rem;
    }
  }
}
:deep .ant-collapse {
  border: none;
  background-color: #ffffff !important;
  font-size: 20rem;
}
:deep .ant-collapse-item {
  overflow: hidden;
}

:deep .ant-collapse-header {
  background: #f6f6f6;
  border-radius: 10rem;
}
:deep .ant-collapse > .ant-collapse-item {
  border: none;
  margin-top: 20rem;
}
:deep .ant-collapse-content {
  border-top: none;
}
:deep
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 20rem;
}
</style>